import {companyPermissions} from "@/mixins/companyPermissions";

import Facebook from "../Facebook/index";
import CarFax from "../CarFax/index";
import EzAutosBuy from "../EzAutosBuy/index";

import IntegrationService from '@/services/integration.service';

const service = new IntegrationService();

export default {
  name: 'Integrations',

  components: {
    Facebook,
    CarFax,
    EzAutosBuy
  },

  data() {
    return {
      integrations: [],
    }
  },

  async mounted() {
    await this.list();
  },

  methods: {
    async list() {
      const res = await service.list();
      if (res && !res.error) {
        this.integrations = res.data.integrations;
      }
    },

    connected(type) {
      return this.integrations.find(el => el.Type === type);
    },

    key(type) {
      return `${type}-${this.integrations.length}`;
    }
  },

  mixins: [companyPermissions]
};