import config from "@/config";
import DateHelper from "@/helpers/date.helper";

import {facebookSdk} from '@/mixins/facebookSdk';

import IntegrationService from '@/services/integration.service';
const service = new IntegrationService();

export default {
  name: 'FacebookIntegration',

  props: ["integration"],

  data() {
    return {
      integrated: false,
      infoModal: false,
      disconnectModal: false,
      integrationCopy: {
        Type: "facebook",
        AppId: config[config.stage].fb_app_id,
        AppSecret: config[config.stage].fb_app_secret,
        PageName: "",
        PageId: "",
        UserName: "",
        UserId: "",
      },
    }
  },

  watch: {
    integration(val) {
      this.integrated = !!val;
      if (this.integrated) {
        this.integrationCopy = {...val};
      }
    }
  },

  mixins: [facebookSdk],

  async mounted() {

    if (this.integration) {
      this.integrated = true;
      this.integrationCopy = {...this.integration};
    }
  },

  computed: {

    pageName() {
      return this.integrationCopy.PageName;
    },

    pageUrl() {
      return 'https://www.facebook.com/' + this.integrationCopy.PageName + '-' + this.integrationCopy.PageId;
    }

  },

  methods: {
    async connect() {
      const res = await service.create(this.integrationCopy);
      if (res && !res.error) {
        this.$notify({type: 'success', message: this.$t('integration_created')});
        this.$emit("change");
      } else {
        this.$notify({type: 'error', message: this.$t('error_creating')});
      }
    },

    async disconnect() {
      const res = await service.delete(this.integrationCopy.Type);
      if (res && !res.error) {
        this.cancelModal();
        this.$emit("change");
        this.$notify({type: 'success', message: this.$t('integration_removed')});
      } else {
        this.cancelModal();
        this.$notify({type: 'error', message: this.$t('error_removing')});
      }
    },

    async doFbLogin() {
      const auth_res = await this.fbLogin(); // Facebook Login

      if (auth_res.status === 'connected' && auth_res.authResponse) {
        let res = null;

        const login_access_token = auth_res.authResponse.accessToken;

        // Exchange Login Access Code For Long-Lived User Access Token
        res = await this.fbApi('/oauth/access_token', 'GET', {
          grant_type: 'fb_exchange_token',
          client_secret: this.integrationCopy.AppSecret,
          client_id: this.integrationCopy.AppId,
          fb_exchange_token: login_access_token
        });

        const access_token = res.access_token;

        // Getting User Id
        res = await this.fbApi('/me', 'GET', {access_token});

        this.integrationCopy.UserId = res.id;
        this.integrationCopy.UserName = res.name;

        // Getting Page Info & Permanent Page Access Token
        res = await this.fbApi(`${res.id}/accounts`, 'GET', {access_token});

        if (res.data) {
          this.integrationCopy.PageName = res.data[0].name;
          this.integrationCopy.PageToken = res.data[0].access_token;
          this.integrationCopy.PageId = res.data[0].id;
          this.integrationCopy.VerifyToken = config[config.stage].fb_webwook_token;
          this.integrationCopy.createdAt = new Date();
          await this.connect();
        }
      }
    },

    openModal(action) {
      if (action === 'info') {
        this.infoModal = true;
      }
      if (action === 'delete') {
        this.disconnectModal = true;
      }
    },

    cancelModal() {
      this.disconnectModal = false;
      this.infoModal = false;
    },
  },

  filters: {

    normalizeDate(date) {
      if (!date) return "N/A";
      return DateHelper.customFormat(date, "DD MMM YYYY h:mm a");
    },

  }
};