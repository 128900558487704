import config from "@/config";

/*global FB*/
/*eslint no-undef: ["error", { "typeof": true }] */

export const facebookSdk = {
    data () {
        return {
            FB: {}
        }
    },

    mounted () {
        const _this = this;

        this.$nextTick(() => {

            window.fbAsyncInit = function () {
                FB.init({
                    appId      : config[config.stage].fb_app_id,
                    status     : true,  // check login status
                    xfbml      : true,  // parse XFBML
                    version    : 'v10.0'
                });

                FB.AppEvents.logPageView();

                _this.FB = FB;
            };

            // Load the SDK Asynchronously
            (function (d, s, id) {
                if (d.getElementById(id)) return;

                const
                    js = d.createElement(s),
                    fjs = d.getElementsByTagName(s)[0];

                js.id = id;
                js.src = '//connect.facebook.net/en_US/sdk.js';
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'))

        });
    },

    methods: {
        fbApi(...params) {
            return new Promise((resolve) => {
                const callback = (response) => {
                    resolve(response);
                };

                if (params.length > 3) {
                    params = params.slice(0, 3);
                }

                params.push(callback);

                FB.api(...params);
            });
        },

        fbGetLoginStatus() {
            return new Promise((resolve) => {

                FB.getLoginStatus((response) => {
                    resolve(response);
                });
            });
        },

        fbLogin(params = {
            scope: 'leads_retrieval,pages_manage_ads,pages_read_engagement,pages_show_list,pages_messaging,pages_manage_metadata'
            //scope: 'pages_show_list,leads_retrieval'
            //scope: 'read_insights,publish_pages,ads_management,ads_read,'
        }) {
            return new Promise((resolve) => {

                FB.login((response) => {
                    resolve(response);
                }, params);
            });
        },

        fbGetAuthResponse() {
            return new Promise((resolve) => {

                resolve(FB.getAuthResponse());
            });
        }
    }
};