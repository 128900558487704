import DateHelper from "@/helpers/date.helper";

import IntegrationService from '@/services/integration.service';
const service = new IntegrationService();

export default {
  name: 'EzAutosBuy',

  props: ["integration"],

  data() {
    return {
      integrated: false,
      loginModal: false,
      disconnectModal: false,
      loading: false,
      integrationCopy: {
        Type: 'ezautosbuy',
        UserName: "",
        Password: "",
      },
    }
  },

  async mounted() {
    if (this.integration) {
      this.integrated = true;
      this.integrationCopy = {...this.integration};
    }
  },

  computed: {
    UserName() {
      return this.integrationCopy.UserName;
    }
  },

  methods: {
    async connect() {
      this.loading = true;

      const res = await service.ezAutosBuyLogin(this.integrationCopy.UserName, this.integrationCopy.Password);
      if (res && !res.error) {
        this.$notify({type: 'success', message: this.$t('integration_created')});
        this.$emit("change");
        this.cancelModal();
      } else {
        this.$notify({type: 'error', message: this.$t('error_creating')});
      }

      this.loading = false;
    },

    async disconnect() {
      const res = await service.delete(this.integrationCopy.Type);
      if (res && !res.error) {
        this.$notify({type: 'success', message: this.$t('integration_removed')});
        this.$emit("change");
        this.cancelModal();
      } else {
        this.cancelModal();
        this.$notify({type: 'error', message: this.$t('error_removing')});
      }
    },

    openModal(action) {
      if (action === 'login') {
        this.loginModal = true;
      }
      if (action === 'delete') {
        this.disconnectModal = true;
      }
    },

    cancelModal() {
      this.disconnectModal = false;
      this.loginModal = false;
    },
  },

  filters: {
    normalizeDate(date) {
      if (!date) return "N/A";
      return DateHelper.customFormat(date, "DD MMM YYYY h:mm a");
    },
  },

  watch: {
    integration(val) {
      if (val) {
        this.integrated = true;
        this.integrationCopy = {...val};
      }
    }
  },
};