import IService from './iservice';
import config from "@/config";
import store from "@/store/store";

const server_base = config[config.stage].ws_server;
const html_base = config[config.stage].html;

class IntegrationsService extends IService {
  constructor(http) {
    super(http);
    this.store = store;
  }

  async create(integration) {
    try {
      const response = await this.http.post(`${server_base}/api/integration`, {integration});
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async edit(integration) {
    try {
      const response = await this.http.put(`${server_base}/api/integration`, {integration});
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async get(id) {
    try {
      let response = await this.http.get(`${server_base}/api/integration/${id}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async delete(type) {
    try {
      const response = await this.http.delete(`${server_base}/api/integration/${type}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async list(limit, skip) {
    try {
      const query = {};

      if (limit) query.limit = limit;
      if (skip) query.skip = skip;

      this.addLoading('list');
      const response = await this.http.get(`${server_base}/api/integration`, query);
      this.delLoading('list');
      return response.data;
    } catch (e) {
      this.delLoading('list');
      return null;
    }
  }

  async carFaxProbe(username, password) {
    try {
      let response = await this.http.post(`${html_base}/carfax/probe`, {username, password});
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async ezAutosBuyLogin(email, password) {
    try {
      const response = await this.http.post(`${server_base}/api/integration/login/ez-autos-buy`, {email, password});
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
}

export default IntegrationsService;