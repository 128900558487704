import { render, staticRenderFns } from "./EzAutosBuy.html?vue&type=template&id=70113daa&scoped=true&external"
import script from "./EzAutosBuy.js?vue&type=script&lang=js&external"
export * from "./EzAutosBuy.js?vue&type=script&lang=js&external"
import style0 from "./EzAutosBuy.scss?vue&type=style&index=0&id=70113daa&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70113daa",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2FSettings%2FIntegrations%2FEzAutosBuy%2Findex.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports