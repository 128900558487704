import DateHelper from "@/helpers/date.helper";

import IntegrationService from '@/services/integration.service';
const service = new IntegrationService();

export default {
  name: 'CarFax',

  props: ["integration"],

  data() {
    return {
      integrated: false,
      loginModal: false,
      disconnectModal: false,
      loading: false,
      integrationCopy: {
        Type: 'carfax',
        AppId: '',
        AppSecret: '',
        PageName: "",
        PageId: "",
        UserName: "",
        Password: "",
        UserId: "",
      },
    }
  },

  watch: {
    integration(val) {
      if (val) {
        this.integrated = true;
        this.integrationCopy = {...val};
      }
    }
  },

  async mounted() {
    if (this.integration) {
      this.integrated = true;
      this.integrationCopy = {...this.integration};
    }
  },

  computed: {
    UserName() {
      return this.integrationCopy.UserName;
    }
  },

  methods: {

    async carFaxProbe() {
      const res = await service.carFaxProbe(this.integrationCopy.UserName, this.integrationCopy.Password);
      return res && !res.error;
    },

    async connect() {1

      this.loading = true;

      const probe = await this.carFaxProbe();

      if (probe) {
        this.integrationCopy.PageId = this.integrationCopy.UserName;
        const res = await service.create(this.integrationCopy);
        if (res && !res.error) {
          this.$notify({type: 'success', message: this.$t('integration_created')});
          this.$emit("change");
          this.cancelModal();
        } else {
          this.$notify({type: 'error', message: this.$t('error_creating')});
        }
      } else {
        this.$notify({type: 'error', message: this.$t('error_creating')});
      }

      this.loading = false;


    },

    async disconnect() {
      const res = await service.delete(this.integrationCopy.Type);
      if (res && !res.error) {
        this.cancelModal();
        this.$notify({type: 'success', message: this.$t('integration_removed')});
        this.$emit("change");
      } else {
        this.cancelModal();
        this.$notify({type: 'error', message: this.$t('error_removing')});
      }
    },

    openModal(action) {
      if (action === 'login') {
        this.loginModal = true;
      }
      if (action === 'delete') {
        this.disconnectModal = true;
      }
    },

    cancelModal() {
      this.disconnectModal = false;
      this.loginModal = false;
    },
  },

  filters: {

    normalizeDate(date) {
      if (!date) return "N/A";
      return DateHelper.customFormat(date, "DD MMM YYYY h:mm a");
    },

  }
};